<template>
  <div class="text-center not-found-container">
    <i class="ti ti-4x ti-warning"></i>
    <h1>{{ $t('pages.not_found.title') }}</h1>
    <p>{{ $t('pages.not_found.text') }}</p>
    <router-link
      class="btn btn-secondary text-light rounded-pill mt-4"
      :to="{ name: 'triotech.front.home' }"
    >
      {{ $t('pages.not_found.homepage') }}
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'NotFoundPage',
    metaInfo() {
      return {
        meta: [
          { name: 'robots', content: 'noindex' },
        ],
      };
    },
  };
</script>
