<template>
  <div id="about">
    <div class="header-image"></div>
    <div class="container">
      <h1 class="text-center">
        {{ $t('pages.about.title') }}
      </h1>
    </div>
    <div class="quote-section">
      <div class="container main-quote">
        <h2 class="text-center">
          {{ $t('pages.about.quote') }}
        </h2>
      </div>
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <h2 class="home-title">
        {{ $t('pages.about.employees_title') }}
      </h2>
      <employees />
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h2 class="home-title">
        {{ $t('pages.about.image_blocks.agency.title') }}
      </h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="$t('pages.about.image_blocks.agency.text')">
      </div>
      <div>
        <div class="d-flex justify-content-center card-scale-animation py-4">
          <router-link
            to="./actualites/pourquoi-faire-appel-au-credit-dimpot-innovation-cii-pour-vos-projets-numeriques-sur-mesure"
          >
            <img
              alt="Logo CII impôts"
              class="cii-logo"
              src="static/images/icons/logo-cii.png"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <animate-numbers :final-numbers="statNumbers" />
    </div>

    <ask-block />
  </div>
</template>

<script>
  import AnimateNumbers from '../components/AnimateNumbers';
  import AskBlock from '../components/AskBlock';
  import Employees from '../components/Employees';

  export default {
    name: 'AboutPage',
    components: {
      AnimateNumbers,
      AskBlock,
      Employees,
    },
    data() {
      return {
        statNumbers: [{
          number: 17,
          text: this.$t('pages.home.animate_numbers.years_of_expertise'),
        }, {
          number: 113,
          text: this.$t('pages.home.animate_numbers.cumulative_experience'),
        }, {
          number: 216,
          text: this.$t('pages.home.animate_numbers.projects_count'),
        }],
      };
    },
  };
</script>
