<template>
  <div id="project">
    <div class="header-image"></div>
    <div class="container">
      <h1 class="text-center">
        {{ $t('pages.project.title') }}
      </h1>
    </div>
    <div class="quote-section">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <h2 class="text-center" v-html="$t('pages.project.quote')"></h2>
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <h2 class="my-5">
        {{ $t('pages.project.grid.title') }}
      </h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('pages.project.grid.text')"></p>
    </div>
    <projects class="py-5" />
    <div id="projects">
      <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
        <h2 class="home-title text-center">
          {{ $t('pages.about.customers_title') }}
        </h2>
        <customers />
      </div>
    </div>
  </div>
</template>

<script>
  import Projects from '../../components/Projects';
  import Customers from '../../components/Customers';

  export default {
    name: 'ProjectIndexPage',
    components: {
      Projects,
      Customers,
    },
  };
</script>
