<template>
  <div class="container">
    <div class="row">
      <div
        v-for="employee in employees"
        :key="employee.id"
        :class="['col-6', 'col-md-4', isEmployeesMosaic ? 'col-lg-2' : 'col-lg-3']"
      >
        <employee
          :employee="employee"
          :is-author="isAuthor"
          :is-focus="isFocus"
          @click.native="selectEmployee"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import Employee from './Employee';

  export default {
    name: 'EmployeesMosaicComponent',
    components: {
      Employee,
    },
    props: {
      employees: {
        type: Array,
        default() {
          return [];
        },
      },
      hasSlider: {
        type: Boolean,
        default: false,
      },
      isFocus: {
        type: Boolean,
        default: true,
      },
      isEmployeesMosaic: {
        type: Boolean,
        default: false,
      },
      isAuthor: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      selectEmployee(event) {
        const { id } = (event.currentTarget || event.target).dataset;

        this.$emit('employee-selected', { initials: id });
      },
    },
  };
</script>
