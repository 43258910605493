<template>
  <div>
    <button
      :class="['btn', buttonClasses]"
      type="button"
      @click="openModal('modalForm')"
    >
      {{ $t(buttonLabel) }}
    </button>
    <sweet-modal
      ref="modalForm"
      class="modal-form"
      modal-theme="triotech"
    >
      <contact-form />
    </sweet-modal>
  </div>
</template>

<script>
  import ContactForm from './ContactForm';

  export default {
    name: 'ContactExpert',
    components: {
      ContactForm,
    },
    props: {
      buttonLabel: {
        type: String,
        default: 'pages.contact.contact_us',
      },
      buttonClasses: {
        type: String,
        default: 'btn-dark text-light rounded-pill',
      },
    },
    methods: {
      openModal(modal) {
        this.$refs[modal].open();
      },
    },
  };
</script>
