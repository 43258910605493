<template>
  <div id="site-map">
    <section class="cover-image"></section>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <div class="row">
        <div class="col">
          <h1>
            {{ $t('pages.site_map.title') }}
          </h1>
        </div>
      </div>
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <div class="row">
        <div class="col">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="$t('pages.site_map.list')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SiteMapPage',
  };
</script>

<style lang="scss" scoped>
  #site-map {
    .cover-image {
      background-image: url('/images/site-map.jpg');
    }
  }
</style>
