<template>
  <div id="contact">
    <div class="header-image"></div>

    <div class="container">
      <h1>
        {{ $t('pages.contact_success.title') }}
      </h1>
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mb-5"
        v-html="$t('pages.contact_success.content')"
      ></div>
      <!-- eslint-enable vue/no-v-html -->
      <div class="mb-5">
        <router-link
          class="btn btn-dark rounded-pill text-light"
          :to="{ name: 'triotech.front.home' }"
        >
          {{ $t('pages.not_found.homepage') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactSuccessPage',
    mounted() {
      const requestTimestamp = this.$route.params.time;

      if (!requestTimestamp || this.$moment(requestTimestamp).isBefore(this.$moment().subtract(30, 'seconds'))) {
        this.$router.push({ name: 'triotech.front.home' });
      }
    },
  };
</script>
