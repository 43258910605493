<template>
  <form
    ref="form"
    class="custom-form"
  >
    <div class="form-title">
      {{ $t("pages.contact.title") }}
    </div>
    <div class="form-group">
      <input
        id="name"
        v-model="contact.name"
        class="form-control"
        :placeholder="$t('pages.contact.label.name')"
        required
        type="text"
      />
    </div>
    <div class="form-group">
      <input
        id="email"
        v-model="contact.email"
        class="form-control"
        :placeholder="$t('pages.contact.label.email')"
        required
        type="email"
      />
    </div>
    <div class="form-group">
      <input
        id="phone"
        v-model="contact.phone"
        class="form-control"
        :placeholder="$t('pages.contact.label.phone')"
        type="tel"
      />
    </div>
    <div class="form-group">
      <textarea
        id="message"
        v-model="contact.message"
        class="form-control"
        :placeholder="$t('pages.contact.label.message')"
        required
        rows="3"
      >
      </textarea>
    </div>
    <div class="form-group form-check d-flex align-items-center">
      <input
        id="checkbox"
        v-model="contact.used_data"
        class="form-check-input"
        required
        type="checkbox"
      />
      <label
        class="form-check-label"
        for="checkbox"
      >
        {{ $t("pages.contact.placeholder.rgpd") }}
      </label>
    </div>
    <div class="text-center">
      <button
        class="btn btn-secondary text-light rounded-pill"
        type="button"
        @click="send"
      >
        <span class="d-none d-sm-inline-block mr-2">
          {{ $t('actions.send') }}
        </span>
        <icon name="paper-plane" />
      </button>
    </div>
  </form>
</template>

<script>
  export default {
    name: 'HomeContact',

    data() {
      return {
        contact: {
          name: null,
          email: null,
          phone: null,
          message: null,
          used_data: false,
        },
      };
    },

    methods: {
      send() {
        if (!this.$refs.form.reportValidity()) {
          return;
        }

        this.$ajax
          .post('public/contact/', {
            contact: this.contact,
          })
          .then((data) => {
            if ('ok' === data.status) {
              const formattedSource = {
                linkedin: 'contactLinkedin',
                googlesearch: 'contactGoogleSearch',
              }[this.$route.query.source.toLowerCase()] || 'contactLandingPage';

              this.$router.push({
                name: 'triotech.front.contact_success',
                params: { time: Date.now() },
                query: { source: formattedSource },
              });
            } else {
              this.$notify({
                title: this.$t('flashes.contact.not_sent_title'),
                text: this.$t('flashes.contact.not_sent'),
                type: 'error',
              });
            }
          })
          .catch(() => {
            this.$notify({
              title: this.$t('flashes.contact.server_error_title'),
              text: this.$t('flashes.contact.server_error'),
              type: 'error',
            });
          });
      },
    },
  }
</script>
