<template>
  <div>
    <div class="customer-container text-center">
      <img
        :alt="customer.label"
        :src="imgurl(customer.image, 'client_thumbnail')"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CustomerComponent',
    props: {
      customer: {
        type: Object,
        default() {
          return {};
        },
      },
    },
  };
</script>
