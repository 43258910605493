<template>
  <div class="row flex-nowrap">
    <div :class="isAuthor ? 'col-4 col-md-3 col-lg-2' : 'col-md-4'">
      <employee
        :employee="employee"
        :is-author="isAuthor"
      />
    </div>
    <div :class="isAuthor ? 'col-8 col-md-9 col-lg-10' : 'col-md-8'">
      <template v-if="!isNewsEmployee">
        <div class="team-text float-left d-lg-block">
          <h3 class="employee-id">
            {{ employee.first_name }}
          </h3>
          <h4>{{ employee.job }}</h4>
          <hr />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="employee.presentation"></p>
        </div>
      </template>
      <template v-else>
        <div class="team-description d-flex flex-column align-items-start justify-content-between">
          <div>
            <small>
              {{ $t('pages.news.written_by') }}
            </small>
            <h3>{{ employee.first_name }}</h3>
          </div>
          <small>{{ employee.job }}</small>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Employee from './Employee';

  export default {
    name: 'EmployeeDescriptionComponent',
    components: {
      Employee,
    },
    props: {
      employee: {
        type: Object,
        default: () => {
        },
      },
      isNewsEmployee: {
        type: Boolean,
        default: false,
      },
      isAuthor: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
