<template>
  <div id="frameworks" class="container">
    <div class="row justify-content-center align-items-center mb-2 mx-5 mx-ld-0">
      <div
        v-for="item in frameworkLogos"
        :key="item.id"
        class="col-6 col-md-3 col-lg-2"
      >
        <div class="card-no-style card-scale-animation card p-3">
          <img
            :alt="item.framework"
            class="card-img"
            :src="item.logo"
          />
          <a
            class="stretched-link"
            :href="item.link"
            target="_blank"
          >
          </a>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-sm-3 mt-lg-0 col-lg-4">
        <div class="card-no-style card card-scale-animation p-3 mb-2">
          <img
            alt="Windev"
            class="card-img framework-logos"
            src="static/images/icons/frameworks_windev.png"
          />
          <a
            class="stretched-link"
            href="/pages/quest-ce-que-windev"
            target="_blank"
          >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FrameworkLogos',
    data() {
      return {
        frameworkLogos: [{
          logo: '../../static/images/icons/frameworks_symfony.png',
          framework: 'Symfony',
          link: '/pages/pourquoi-choisir-symfony-pour-vos-projets-web',
        }, {
          logo: '../../static/images/icons/frameworks_vuejs.png',
          framework: 'Vue.js',
          link: '/pages/vue-js-le-framework-javascript-progressif-qui-sadapte-a-tous-vos-besoins',
        }, {
          logo: '../../static/images/icons/frameworks_sylius.png',
          framework: 'Sylius',
          link: '/pages/sylius-la-solution-e-commerce-hybride-et-sur-mesure',
        }, {
          logo: '../../static/images/icons/frameworks_nativescript.png',
          framework: 'NativeScript',
          link: '/actualites/pourquoi-nativescript-fait-la-difference-sur-vos-applications-mobiles',
        }],
      };
    },
  }
</script>
