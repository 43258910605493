<template>
  <div>
    <picture>
      <source
        v-for="size in Object.keys(responsiveBreakpoints)"
        :key="size"
        :media="responsiveBreakpoints[size]"
        :srcset="staticImgUrl(
          `${path}`,
          `${imageName}`,
          `${size}`,
          `${extension}`
        )"
      />
      <img
        :alt="alt"
        :class="cssClass"
        :src="src"
      />
    </picture>
  </div>
</template>

<script>
  export default {
    name: 'ResponsivePictureStatic',
    props: {
      alt: {
        type: String,
        default: '',
      },
      cssClass: {
        type: String,
        default: '',
      },
      extension: {
        type: String,
        default: '',
      },
      imageName: {
        type: String,
        default: '',
      },
      path: {
        type: String,
        default: '',
      },
      src: {
        type: String,
        default: '',
      },
      responsiveBreakpoints: {
        type: Object,
        default: () => ({
          xl: '(min-width: 1200px)',
          lg: '(min-width: 992px)',
          md: '(min-width: 768px)',
          sm: '(min-width: 576px)',
          xs: '(min-width: 320px)',
        }),
      },
    },
  };
</script>
