<template>
  <div class="container mt-4">
    <div class="row">
      <router-link
        v-for="item in skills"
        :key="item.id"
        class="col-md-6 col-lg-4 mb-4 card-scale-animation text-decoration-none"
        :to="{
          name: 'triotech.front.expertise.show',
          params: { slug: item.slug }
        }"
      >
        <div class="card p-4 rounded-sm shadow h-100" data-aos="flip-left">
          <div class="card-header border-bottom-0 bg-light text-center text-muted">
            <icon
              v-if="item.icon"
              class="mb-4"
              :name="item.icon.replace('solid/', '')"
              scale="3"
            />
            <h4 class="card-title text-uppercase mb-0">
              {{ item.label }}
            </h4>
          </div>
          <div class="card-body d-none d-md-block px-4">
            <div class="card-text text-center text-muted">
              {{ item.short_description }}
            </div>
          </div>
          <div class="card-footer d-none d-md-block bg-light text-muted border-top-0 text-center">
            <i class="ti-custom ti ti-3x ti-plus-circle"></i>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SkillsCards',

    data() {
      return {
        skills: [],
      };
    },

    beforeMount() {
      this.fetchSkills();
    },

    methods: {
      async fetchSkills() {
        this.skills = await this.$ajax.get('public/expertises');
      },
    },
  };
</script>
