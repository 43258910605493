<template>
  <picture>
    <source
      :class="cssClass"
      media="(min-width: 1200px)"
      :srcset="imgurl(desktopBanner, 'dynamic_page_banner_xl')"
    />
    <source
      :class="cssClass"
      media="(min-width: 992px)"
      :srcset="imgurl(desktopBanner, 'dynamic_page_banner_lg')"
    />
    <source
      :class="cssClass"
      media="(min-width: 768px)"
      :srcset="imgurl(desktopBanner, 'dynamic_page_banner_md')"
    />
    <source
      media="(min-width: 576px)"
      :srcset="imgurl(mobileBanner, 'dynamic_page_banner_sm')"
    />
    <source
      media="(min-width: 320px)"
      :srcset="imgurl(mobileBanner, 'dynamic_page_banner_xs')"
    />
    <img
      :alt="alt"
      :class="cssClass"
      :src="imgurl(src, 'dynamic_page_banner')"
    />
  </picture>
</template>

<script>
  export default {
    name: 'ResponsiveBanners',

    props: {
      alt: {
        type: String,
        default: '',
      },
      cssClass: {
        type: String,
        default: '',
      },
      desktopBanner: {
        type: String,
        default: '',
      },
      mobileBanner: {
        type: String,
        default: '',
      },
      filterName: {
        type: String,
        required: true,
      },
      src: {
        type: String,
        required: true,
      },
    },
  }
</script>
