<template>
  <div id="news">
    <div class="header-image"></div>
    <div class="container text-center">
      <h1>
        {{ $t('pages.news.title') }}
      </h1>
    </div>
    <div class="quote-section">
      <div class="container">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h2 class="text-center" v-html="$t('pages.news.quote')"></h2>
      </div>
    </div>
    <news-cards />
  </div>
</template>

<script>
  import NewsCards from '../../components/NewsCards';

  export default {
    name: 'NewsIndexPage',
    components: {
      NewsCards,
    },
    data() {
      return {
        isLoading: false,
        morePage: true,
      };
    },
  };
</script>
