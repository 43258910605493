<template>
  <div class="social-networks-container card-scale-animation">
    <a
      v-for="social in socials"
      :key="social.id"
      class="btn-social"
      :href="social.link"
      target="_blank"
    >
      <icon :name="social.icon" />
    </a>
  </div>
</template>

<script>
  export default {
    name: 'SocialNetworkComponent',
    data() {
      return {
        socials: [{
          icon: 'brands/linkedin',
          link: 'https://www.linkedin.com/company/triotech-france',
        }],
      };
    },
  };
</script>
