<template>
  <div id="expertise">
    <div class="header-image"></div>

    <div class="container text-center">
      <h1>
        {{ $t('pages.expertise.title') }}
      </h1>
    </div>

    <div class="quote-section">
      <div class="container main-quote">
        <h2 class="text-center">
          {{ $t('pages.expertise.quote') }}
        </h2>
      </div>
    </div>

    <image-block
      extension="jpg"
      image-name="developer"
      :image-on-right="true"
      :link="false"
      path="../../static/images/content_page/"
      src="../../static/images/content_page/developer.jpg"
      :text="$t('pages.expertise.image_blocks.approach.text')"
      :title="$t('pages.expertise.image_blocks.approach.title')"
    />

    <div class="container-fluid bg-extra-light-grey py-0 py-lg-5">
      <div class="container py-1 py-lg-5">
        <h2>
          {{ $t('pages.expertise.expertise_area.title') }}
        </h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="mb-5" v-html="$t('pages.expertise.expertise_area.text')"></p>
        <skills-cards />
      </div>
    </div>

    <div class="container py-0 py-lg-5 px-4 px-md-0">
      <h2>
        {{ $t('pages.expertise.process.title') }}
      </h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$t('pages.expertise.process.text')"></p>
    </div>

    <image-block
      class="bg-primary"
      extension="jpg"
      image-name="teamwork"
      :image-on-right="false"
      :link="false"
      path="../../static/images/content_page/"
      src="../../static/images/images/content_page/teamwork.jpg"
      :text="$t('pages.expertise.image_blocks.process.text')"
    />

    <ask-block />
  </div>
</template>

<script>
  import AskBlock from '../components/AskBlock';
  import ImageBlock from '../components/ImageBlock';
  import SkillsCards from '../components/SkillsCards';

  export default {
    name: 'ExpertisePage',
    components: {
      AskBlock,
      ImageBlock,
      SkillsCards,
    },
    data() {
      return {
        link: {
          text: this.$t('pages.project.all'),
          name: 'triotech.front.project.index',
          icon: 'ti-arrow-right',
        },
      };
    },
  };
</script>
