<template>
  <div class="ask-block-container">
    <div class="grey-cover-container">
      <div class="container content-container">
        <!-- eslint-disable vue/no-v-html -->
        <h2
          class="ask-block-title mx-3 mx-md-0"
          v-html="$t('pages.ask.quote')"
        ></h2>
        <!-- eslint-enable vue/no-v-html -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="pb-3 mx-3 mx-md-0" v-html="$t('pages.ask.text')"></p>
        <contact-expert
          button-classes="btn-secondary text-light rounded-pill py-3 font-weight-bold"
          button-label="pages.ask.action"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ContactExpert from './ContactExpert';

  export default {
    name: 'AskBlockComponent',
    components: {
      ContactExpert,
    },
    methods: {
      openModal(modal) {
        this.$refs[modal].open();
      },
    },
  };
</script>
