import AOS from 'aos';
import Root from '@triotech/vue-core/src/mixins/Root';
import Textra from 'vue-textra'
import Vue from '@triotech/vue-core/src/vendor/vue';
import VueGtm from 'vue-gtm';

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import '@triotech/vue-core/src/helpers/image';
import '@triotech/vue-core/src/helpers/storage-cleaner';
import '@triotech/vue-core/src/helpers/window';

import '@triotech/vue-core/src/vendor/bootstrap-vue';
import '@triotech/vue-core/src/vendor/google/analytics';
import '@triotech/vue-core/src/vendor/google/maps';
import '@triotech/vue-core/src/vendor/moment';
import '@triotech/vue-core/src/vendor/sweet-modal-vue';
import '@triotech/vue-core/src/vendor/trio-icons';
import '@triotech/vue-core/src/vendor/vue-autosize';
import '@triotech/vue-core/src/vendor/vue-awesome';
import '@triotech/vue-core/src/vendor/vue-meta';
import '@triotech/vue-core/src/vendor/vue-multiselect';
import '@triotech/vue-core/src/vendor/vue-notification';
import '@triotech/vue-core/src/vendor/vue-scrollto';
import '@triotech/vue-core/src/vendor/vue-slick';

import 'aos/dist/aos.css';
import 'bootstrap/js/src/collapse';
import 'vue-awesome/icons';

Vue.mixin({
  metaInfo() {
    let metaInfo = {};
    const { name } = this.$options;
    const regex = new RegExp('(index|show|edit|delete)?page$', 'i');

    if (name && name.match(regex)) {
      const page = this.$voca(name.replace(regex, '')).snakeCase().lowerCase().value();

      metaInfo = {
        title: this.$t(`pages.${page}.meta.title`),
        meta: [
          { name: 'description', content: this.$t(`pages.${page}.meta.description`) },
          { property: 'og:url', content: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
          { property: 'og:title', content: this.$t(`pages.${page}.meta.title`) },
          { property: 'og:description', content: this.$t(`pages.${page}.meta.description`) },
        ],
        link: [
          { rel: 'canonical', href: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
        ],
      };
    }

    return metaInfo;
  },
});

library.add(fas, far);

export default new Vue({
  mixins: [
    Root,
  ],
  created() {
    this.$moment.locale('fr');

    Vue.use(Textra);

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    const gtmConfig = this.$config.get('gtm', false);
    if (gtmConfig && !Object.prototype.hasOwnProperty.call(global, '__PRERENDER_INJECTED')) {
      Object.assign(gtmConfig, {
        vueRouter: Root.router,
      });
      Vue.use(VueGtm, gtmConfig);
    }
  },
});

Vue.mixin({
  methods: {
    imgurl(path = '', filter = '') {
      return (path && filter) ? path.toString().replace('__FILTER__', filter) : null;
    },
    staticImgUrl(
      path = '',
      name = '',
      size = '',
      extension = '',
    ) {
      return `${path}${name}_${size}.${extension}`;
    },
  },
});

AOS.init({
  disable: 768 > window.innerWidth,
});
