<template>
  <a
    v-if="isExternal"
    :alt="link.text"
    :href="link.name"
    target="_blank"
  >
    {{ link.text }} <i class="ti" :class="link.icon"></i>
  </a>
  <router-link
    v-else
    :alt="link.text"
    :to="link"
  >
    {{ link.text }} <i class="icon ti ti-lg" :class="link.icon"></i>
  </router-link>
</template>

<script>
  export default {
    name: 'LinkIconComponent',
    props: {
      link: {
        type: Object,
        default() {
          return {
            name: 'triotech.front.home',
            text: '',
            icons: '',
          };
        },
      },
    },
    computed: {
      isExternal() {
        return this.link.name && 0 === this.link.name.indexOf('http');
      },
    },
  };
</script>
