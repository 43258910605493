<template>
  <div class="map-container">
    <l-map :center="center" :zoom="zoom">
      <l-tile-layer :attribution="attribution" :options="options" :url="url" />
      <l-marker :icon="icon" :lat-lng="latLng" />
    </l-map>
  </div>
</template>

<script>

  import {
    LMap, LMarker, LTileLayer,
  } from 'vue2-leaflet';
  import 'leaflet/dist/leaflet.css';

  export default {
    name: 'LeafletMap',
    components: {
      LMap,
      LMarker,
      LTileLayer,
    },
    props: {
      url: {
        type: String,
        default: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      },
      attribution: {
        type: String,
        default: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      },
      latLng: {
        type: Array,
        default: () => [0, 0],
      },
      center: {
        type: Array,
        default: () => [0, 0],
      },
      zoom: {
        type: Number,
        default: 13,
      },
      options: {
        type: Object,
        default: () => ({ subdomains: ['mt0', 'mt1', 'mt2', 'mt3'] }),
      },
    },
    data() {
      return {
        icon: window.L.icon({
          iconUrl: '/static/images/pointeur-agence.png',
          iconSize: [34, 50],
          iconAnchor: [34, 50],
          shadowUrl: '',
          shadowSize: '',
          shadowAnchor: '',
        }),
      };
    },
  };
</script>
