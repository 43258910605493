<template>
  <div id="contact">
    <div class="header-image"></div>

    <div class="container text-center">
      <h1>
        {{ $t('pages.contact.title') }}
      </h1>
    </div>

    <div class="quote-section">
      <div class="container main-quote">
        <h2 class="text-center">
          {{ $t('pages.contact.quote') }}
        </h2>
      </div>
    </div>

    <div class="container py-5">
      <div class="row">
        <div class="col-md-4 px-4 mb-3">
          <div class="h3">
            <a
              class="mr-2"
              href="https://goo.gl/maps/LtdoE4GAnXY5py4K7"
              target="_blank"
            >
              <img
                alt="Position agence Triotech"
                src="../../static/images/brand/pointeur-agence.png"
                width="25px"
              />
            </a>
            {{ $t('pages.contact.address.title') }}
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <address class="ml-4 ml-lg-0 my-lg-auto" v-html="$t('pages.contact.address.text')"></address>
        </div>
        <div class="col-md-4 px-4 mb-3">
          <div class="h3">
            {{ $t('pages.contact.coordinate.title') }}
          </div>
          <div class="ml-4 ml-lg-0 my-3">
            <a
              class="link"
              :href="`tel:${$t('pages.contact.coordinate.call_phone')}`"
              @click="trackEvent($event, {
                category: 'CTA',
                action: 'contact_phone',
              })"
            >
              {{ $t('pages.contact.coordinate.phone') }}
            </a>
            <br />
            <a
              class="link"
              :href="`mailto:${$t('pages.contact.coordinate.mail')}`"
              @click="trackEvent($event, {
                category: 'CTA',
                action: 'contact_email'
              })"
            >
              {{ $t('pages.contact.coordinate.mail') }}
            </a>
          </div>
        </div>
        <div class="col-md-4 px-4 mb-3">
          <div class="h3">
            {{ $t('pages.contact.social.title') }}
          </div>
          <social-networks class="ml-4 ml-lg-0 my-3" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-4 mb-2 text-center">
          <contact-expert
            button-classes="btn-block btn-dark text-light rounded-pill"
          />
        </div>
        <div class="col-sm-12 col-md-4 mb-2 text-center">
          <button
            class="btn btn-dark rounded-pill text-light btn-block"
            type="button"
            @click="openModal('rgpdForm')"
          >
            {{ $t('pages.rgpd.mydata') }}
          </button>
        </div>
        <div class="col-sm-12 col-md-4 mb-2 text-center">
          <button
            class="btn btn-dark rounded-pill text-light btn-block"
            type="button"
            @click="openModal('recruitment')"
          >
            {{ $t('pages.recruitment.button') }}
          </button>
        </div>
      </div>
    </div>

    <leaflet-map
      :center="marker"
      :lat-lng="marker"
    />

    <sweet-modal
      ref="rgpdForm"
      modal-theme="triotech"
    >
      <rgpd-form />
    </sweet-modal>

    <sweet-modal
      ref="recruitment"
      modal-theme="triotech"
    >
      <p class="form-title text-left">
        {{ $t('pages.recruitment.title') }}
      </p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="text-left" v-html="$t('pages.recruitment.text')"></p>
      <a class="btn btn-light text-dark" :href="`mailto:${$t('pages.recruitment.mail')}`">
        {{ $t('pages.recruitment.mail') }}
      </a>
    </sweet-modal>
  </div>
</template>

<script>
  import ContactExpert from '../components/ContactExpert';
  import LeafletMap from '../components/LeafletMap';
  import RgpdForm from '../components/RGPDForm';
  import SocialNetworks from '../components/SocialNetworks';

  export default {
    name: 'ContactPage',
    components: {
      ContactExpert,
      LeafletMap,
      RgpdForm,
      SocialNetworks,
    },
    computed: {
      marker() {
        return [this.$config.get('google_maps').latitude, this.$config.get('google_maps').longitude];
      },
    },
    methods: {
      openModal(modal) {
        this.$refs[modal].open();
      },
      trackEvent(event, trackEvent) {
        event.preventDefault();
        event.stopPropagation();
        const href = event.currentTarget.getAttribute('href');
        this.$gtm.trackEvent(trackEvent);

        setTimeout(() => {
          window.location = href;
        }, 100);

        return false;
      },
    },
  };
</script>
