<template>
  <div
    id="animates-numbers"
    class="container py-1 py-lg-5"
  >
    <div class="animates-number-container">
      <div class="row">
        <div
          v-for="finalNumber in finalNumbers"
          :key="finalNumber.id"
          :class="`col-sm-${12 / finalNumbers.length}`"
        >
          <animate-number
            :animate-number="animateNumber"
            :final-number="finalNumber.number"
            :icon="finalNumber.icon"
          >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <b v-html="finalNumber.text"></b>
          </animate-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AnimateNumber from './AnimateNumber';

  export default {
    name: 'AnimateNumbersComponent',
    components: { AnimateNumber },
    props: {
      finalNumbers: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        animateNumber: false,
        isAnimating: false,
      };
    },
    mounted() {
      this.animateOnScroll();
      window.addEventListener('scroll', this.animateOnScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.animateOnScroll);
    },
    methods: {
      animateOnScroll() {
        const animateNumberBottom = this.$el.offsetTop - this.$el.offsetHeight * 3;
        if (window.pageYOffset >= animateNumberBottom) {
          if (!this.isAnimating) {
            this.animateNumber = true;
            this.isAnimating = true;
          }
        }
      },
    },
  };
</script>
