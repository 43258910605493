<template>
  <div id="landing-page">
    <div class="landing-page">
      <div class="container-fluid landing-title px-lg-5">
        <div class="row align-items-end align-items-lg-center h-100">
          <div class="col-12 col-lg-7 d-flex flex-column align-items-center">
            <div class="landing-card d-inline-block p-4 p-lg-0">
              <p class="catch-title mb-0 pb-lg-4">
                {{ $t("pages.home.header.title") }}
              </p>
              <p class="catch-subtitle mb-0 pb-lg-4">
                {{ $t("pages.home.header.typed.intro") }}
                <textra
                  class="textra"
                  :data="typedStrings"
                  filter="flip"
                  :infinite="true"
                  :timer="2"
                />
              </p>
              <div class="d-none d-lg-block w-100">
                <contact-expert
                  button-classes="btn btn-lg btn-dark text-light rounded-pill font-weight-bold mt-4 mb-lg-3 py-lg-3 px-lg-5"
                  :button-label="contactButtonText"
                />
              </div>
            </div>
            <div class="text-center text-lg-left d-block d-lg-none">
              <contact-expert
                button-classes="btn btn-lg btn-secondary text-light rounded-pill font-weight-bold mt-4 mb-lg-3 py-lg-3 px-lg-5"
                :button-label="contactButtonText"
              />
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="d-none d-lg-block">
              <picture>
                <source srcset="/images/resized/landing_page/desktop.webp" type="image/webp" />
                <img class="img-fluid w-100" loading="lazy" src="/images/resized/landing_page/desktop.png" />
              </picture>
            </div>
            <div class="d-block d-lg-none">
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="3000"
              >
                <b-carousel-slide>
                  <template #img>
                    <picture>
                      <source srcset="/images/resized/landing_page/1.webp" type="image/webp" />
                      <img class="d-block img-fluid w-100" loading="lazy" src="/images/resized/landing_page/1.jpg" />
                    </picture>
                  </template>
                </b-carousel-slide>
                <b-carousel-slide>
                  <template #img>
                    <picture>
                      <source srcset="/images/resized/landing_page/2.webp" type="image/webp" />
                      <img class="d-block img-fluid w-100" loading="lazy" src="/images/resized/landing_page/2.jpg" />
                    </picture>
                  </template>
                </b-carousel-slide>
                <b-carousel-slide>
                  <template #img>
                    <picture>
                      <source srcset="/images/resized/landing_page/3.webp" type="image/webp" />
                      <img class="d-block img-fluid w-100" loading="lazy" src="/images/resized/landing_page/3.jpg" />
                    </picture>
                  </template>
                </b-carousel-slide>
                <b-carousel-slide>
                  <template #img>
                    <picture>
                      <source srcset="/images/resized/landing_page/4.webp" type="image/webp" />
                      <img class="d-block img-fluid w-100" loading="lazy" src="/images/resized/landing_page/4.jpg" />
                    </picture>
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>
        </div>
      </div>
      <div class="quote-section quote">
        <div class="container main-quote">
          <h1 class="text-light py-0 my-0">
            {{ $t("pages.landing.content.banner") }}
          </h1>
        </div>
      </div>
    </div>
    <div class="container-fluid py-5 bg-extra-light-grey">
      <h2 class="text-center home-title mb-0 pb-2">
        {{ $t("pages.home.skills.title") }}
      </h2>
      <h3 class="text-center text-muted pt-0">
        {{ $t("pages.home.skills.intro") }}
      </h3>
      <div class="py-5">
        <skills-cards />
      </div>
      <div class="text-center">
        <contact-expert
          button-classes="btn btn-lg btn-secondary text-light rounded-pill font-weight-bold mt-4 mb-lg-3 py-lg-3 px-lg-5"
          button-label="pages.landing.button.skills"
        />
      </div>
    </div>
    <div class="container-fluid py-5 bg-white">
      <h2 class="text-center home-title mb-0 pb-2">
        {{ $t("pages.project.title") }}
      </h2>
      <projects class="py-5" />
      <div class="text-center">
        <contact-expert
          button-classes="btn btn-lg btn-secondary text-light rounded-pill font-weight-bold mt-4 mb-lg-3 py-lg-3 px-lg-5"
          button-label="pages.landing.button.projects"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ContactExpert from '../components/ContactExpert';
  import Projects from '../components/Projects';
  import SkillsCards from '../components/SkillsCards';

  export default {
    name: 'LandingPage',
    components: {
      Projects,
      SkillsCards,
      ContactExpert,
    },
    metaInfo() {
      let metaTitle = null;
      let metaDescription = null;

      if ('triotech.front.web' === this.$route.name) {
        metaTitle = this.$t('pages.landing.meta.title.web');
        metaDescription = this.$t('pages.landing.meta.description.web');
      } else if ('triotech.front.software' === this.$route.name) {
        metaTitle = this.$t('pages.landing.meta.title.software');
        metaDescription = this.$t('pages.landing.meta.description.software');
      }

      return {
        title: metaTitle,
        meta: [
          { property: 'og:url', content: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
          { property: 'og:title', metaTitle },
          { property: 'og:description', content: metaDescription },
          { name: 'description', content: metaDescription },
        ],
        link: [
          { rel: 'canonical', href: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
        ],
      };
    },
    data() {
      return {
        contactButtonText: null,
        typedStrings: [
          this.$t('pages.home.header.typed.1'),
          this.$t('pages.home.header.typed.2'),
          this.$t('pages.home.header.typed.3'),
          this.$t('pages.home.header.typed.4'),
          this.$t('pages.home.header.typed.5'),
        ],
      }
    },
    mounted() {
      if ('triotech.front.web' === this.$route.name) {
        this.contactButtonText = this.$t('pages.landing.button.web');
      } else if ('triotech.front.software' === this.$route.name) {
        this.contactButtonText = this.$t('pages.landing.button.software');
      }
    },
  }
</script>
