<template>
  <div
    v-if="null === project"
    class="container loader text-center"
  >
    <i class="ti ti-2x ti-spin ti-refresh"></i>
  </div>
  <div v-else>
    <navigation
      :index="{ name: 'triotech.front.project.index' }"
      :links="project._links"
      param="slug"
      route="triotech.front.project.show"
    />
    <div>
      <ResponsiveBanners
        :alt="project.cover_image_title"
        css-class="img-fluid"
        :desktop-banner="project.cover_image"
        filter-name="dynamic_page_banner"
        :mobile-banner="project.cover_small_image"
        :src="project.cover_image"
      />
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <div class="d-flex flex-column align-items-center">
        <p class="text-center font-italic">
          {{ project.testimony_start }}
        </p>
        <small class="testimony-author">
          {{ project.witness }}
        </small>
      </div>
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <h1 class="text-center mb-2 pb-0">
        {{ project.label }}
      </h1>
      <p class="category">
        {{ project.category && project.category.label }}
      </p>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="project.description_project"></div>
      <ResponsivePicture
        :alt="project.first_image_alt"
        css-class="card-img"
        filter-name="dynamic_page_content"
        :src="project.first_image"
      />
    </div>
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <h2>
        {{ $t('pages.project.project_title') }}
      </h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="project.description_context"></div>
      <h2>
        {{ $t('pages.project.realisation_title') }}
      </h2>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="project.production_triotech"></div>
      <h2>
        {{ $t('pages.project.expertise_title') }}
      </h2>
      <div class="row justify-content-center">
        <div
          v-for="techno in project.technologies"
          :key="techno.slug"
          class="col-4 col-md-2 m-3"
        >
          <div class="position-relative d-flex flex-column align-items-center border rounded p-2">
            <img
              :alt="techno.label"
              class="img-fluid"
              :src="imgurl(techno.image, 'technology_thumbnail')"
            />
            <div class="text-center">
              {{ techno.label }}
            </div>

            <router-link
              v-if="null !== techno.expertise && null !== techno.expertise.slug"
              class="stretched-link"
              :to="{
                name: 'triotech.front.expertise.show',
                params: {
                  slug: techno.expertise.slug,
                },
              }"
            />
          </div>
        </div>
      </div>
      <h2>
        {{ $t('pages.project.team_title') }}
      </h2>
      <div class="team-container text-center">
        <employees-mosaic
          :employees="project.employees"
          :is-employees-mosaic="true"
          :is-focus="false"
        />
      </div>
      <div class="card">
        <ResponsivePicture
          v-for="(image, index) in project.images"
          :key="index"
          :alt="image"
          css-class="card-img-top"
          filter-name="dynamic_page_content"
          :src="image"
        />
        <div
          v-if="null !== project.testimony_end"
          class="card-body"
        >
          {{ project.testimony_end }}
        </div>
      </div>
    </div>
    <projects
      class="py-5"
      :is-related-content="true"
    />
    <ask-block />
  </div>
</template>

<script>
  import Navigation from '@triotech/vue-core/src/components/Navigation';
  import AskBlock from '../../components/AskBlock';
  import EmployeesMosaic from '../../components/EmployeesMosaic';
  import Projects from '../../components/Projects';
  import ResponsivePicture from '../../components/ResponsivePicture';
  import ResponsiveBanners from '../../components/ResponsiveBanners';

  export default {
    name: 'ProjectShowPage',
    components: {
      ResponsiveBanners,
      AskBlock,
      EmployeesMosaic,
      Navigation,
      Projects,
      ResponsivePicture,
    },
    metaInfo() {
      if (null !== this.project) {
        return {
          title: this.project.meta_title,
          meta: [
            { property: 'og:url', content: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
            { property: 'og:title', content: this.project.meta_title },
            { property: 'og:description', content: this.project.meta_description },
            { name: 'description', content: this.project.meta_description },
          ],
          link: [
            { rel: 'canonical', href: this.$config.get('basepath') + window.location.pathname.replace(/\/$/, '') },
          ],
        };
      }
      return {};
    },
    data() {
      return {
        project: null,
      };
    },
    async created() {
      let preview = '';

      if (undefined !== this.$route.query.preview && 'true' === this.$route.query.preview) {
        preview = '?preview=true';
      }

      this.project = await this.$ajax
        .get(`public/projects/${this.$route.params.slug}${preview}`)
        .catch(() => {
          this.$router.push({ name: 'triotech.front.not_found' });
        });
    },
  };
</script>
