<template>
  <picture>
    <source
      v-for="size in Object.keys(responsiveBreakpoints)"
      :key="size"
      :media="responsiveBreakpoints[size]"
      :srcset="imgurl(src, `${filterName}_${size}`)"
    />
    <img
      :alt="alt"
      :class="cssClass"
      :src="imgurl(src, filterName)"
    />
  </picture>
</template>

<script>
  export default {
    name: 'ResponsivePicture',
    props: {
      alt: {
        type: String,
        default: '',
      },
      cssClass: {
        type: String,
        default: '',
      },
      filterName: {
        type: String,
        required: true,
      },
      src: {
        type: String,
        required: true,
      },
      responsiveBreakpoints: {
        type: Object,
        default: () => ({
          xl: '(min-width: 1200px)',
          lg: '(min-width: 992px)',
          md: '(min-width: 768px)',
          sm: '(min-width: 576px)',
          xs: '(min-width: 320px)',
        }),
      },
    },
  };
</script>
