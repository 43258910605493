<template>
  <div class="navigation">
    <router-link class="custom-navigation btn btn-back" :to="index">
      <div class="text">
        {{ indexText }}
      </div>
    </router-link>
    <router-link v-if="previous" class="btn btn-nav btn-prev" :to="previous">
      <i class="ti ti-2x ti-fw ti-arrow-left"></i>
      <div class="text">
        {{ prevText }}
      </div>
    </router-link>
    <router-link v-if="next" class="btn btn-nav btn-next" :to="next">
      <div class="text">
        {{ nextText }}
      </div>
      <i class="ti ti-2x ti-fw ti-arrow-right"></i>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'NavigationComponent',
    props: {
      index: {
        type: Object,
        default: () => ({}),
      },
      links: {
        type: Object,
        default: () => ({}),
      },
      route: {
        type: String,
        default: '',
      },
      indexText: {
        type: String,
        default() {
          return this.$t('actions.list');
        },
      },
      prevText: {
        type: String,
        default() {
          return this.$t('actions.prev');
        },
      },
      nextText: {
        type: String,
        default() {
          return this.$t('actions.next');
        },
      },
      param: {
        type: String,
        default: 'id',
      },
    },
    data() {
      return {
        previous: null,
        next: null,
      };
    },
    mounted() {
      this.previous = this.build('previous');
      this.next = this.build('next');
    },
    methods: {
      build(link) {
        const data = this.links[link];
        const href = data && data.href;
        const matches = href && (href.match(/\/([\d]+)$/) || href.match(/\/([\w-]+)$/));
        const param = matches && 1 < matches.length && matches[1];

        return !!href && {
          name: this.route,
          params: {
            [this.param]: param,
          },
        };
      },
    },
  };
</script>
