<template>
  <div
    :class="[
      'team-member-container', {
        'is-focus': isFocus,
        'overflow-hidden': isAuthor,
        'rounded-circle': isAuthor,
        'shadow': isAuthor,
      },
    ]"
    :data-id="employee.initials"
  >
    <ResponsivePicture
      :alt="employee.first_name"
      filter-name="employee_thumbnail"
      :src="employee.image"
    />
    <div class="hover">
      <span>
        <i v-if="isFocus" class="ti ti-eye ti-2x"></i>
        <small v-else>{{ employee.job }}</small>
      </span>
    </div>
  </div>
</template>

<script>
  import ResponsivePicture from './ResponsivePicture';

  export default {
    name: 'EmployeeComponent',
    components: {
      ResponsivePicture,
    },
    props: {
      employee: {
        type: Object,
        default() {
          return {};
        },
      },
      isFocus: {
        type: Boolean,
        default: false,
      },
      isPreview: {
        type: Boolean,
        default: false,
      },
      isAuthor: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
