<template>
  <div class="menu">
    <nav id="navbarDesktop" class="navbar fixed-top border-bottom flex-nowrap navbar-expand-xl navbar-light bg-light">
      <div class="container">
        <router-link
          class="navbar-brand"
          :to="{ name: 'triotech.front.home' }"
        >
          <img
            :alt="$t('title')"
            height="60"
            src="/static/images/brand/logo-couleur.png"
            width="225"
          />
        </router-link>
        <div class="container justify-content-end d-none d-xl-flex">
          <ul class="navbar-nav">
            <li
              v-for="item in menu.filter(menu => !menu.burgerMenuOnly)"
              :key="item.id"
              class="nav-item text-decoration-none"
            >
              <router-link
                class="nav-link text-uppercase"
                :to="item.link"
              >
                {{ item.title }}
              </router-link>
            </li>
            <contact-expert
              button-classes="btn-secondary text-light rounded-pill"
              button-label="pages.ask.action"
            />
          </ul>
        </div>
      </div>
      <button
        aria-label="Menu toggle"
        class="navbar-toggler rounded-0"
        data-target="#navbarMobileContent"
        data-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </nav>
    <div
      id="navbarMobile"
      class="accordion d-block d-xl-none border-bottom fixed-top"
    >
      <div
        id="navbarMobileContent"
        class="collapse align-items-center"
      >
        <div class="navbar navbar-light bg-light justify-content-center">
          <ul class="list-group list-group-flush">
            <li
              v-for="item in menu"
              :key="item.id"
              class="list-group-item text-center text-uppercase"
            >
              <router-link
                class="nav-link text-uppercase"
                data-target="#navbarMobileContent"
                data-toggle="collapse"
                :to="item.link"
              >
                {{ item.title }}
              </router-link>
            </li>
            <li class="list-group text-center p-2">
              <contact-expert
                button-classes="btn-link rounded-pill"
                button-label="pages.ask.action"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ContactExpert from './ContactExpert';

  export default {
    name: 'StickyMenuComponent',
    components: {
      ContactExpert,
    },
    data() {
      return {
        menu: [{
          id: 1,
          title: this.$t('menu.agency'),
          link: {
            name: 'triotech.front.about',
          },
        }, {
          id: 2,
          title: this.$t('menu.expertise'),
          link: {
            name: 'triotech.front.expertise',
          },
        }, {
          id: 3,
          title: this.$t('menu.projects'),
          link: {
            name: 'triotech.front.project.index',
          },
        }, {
          id: 4,
          title: this.$t('menu.news'),
          link: {
            name: 'triotech.front.news.index',
          },
        }, {
          id: 5,
          title: this.$t('menu.contact'),
          link: {
            name: 'triotech.front.contact',
          },
        }],
        isOpen: false,
      };
    },
    mounted() {
      this.$bus.$on('animateMenu', this.animateMenu);
    },
    methods: {
      openModal(modal) {
        this.$refs[modal].open();
      },
      animateMenu() {
        this.isOpen = !this.isOpen;
        document.body.style.overflow = this.isOpen ? 'hidden' : null;
      },
    },
  };
</script>
