<template>
  <div id="rgpd-form">
    <h2 class="text-left">
      {{ $t('pages.rgpd.form_title') }}
    </h2>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="text-left" v-html="$t('pages.rgpd.form_intro')"></p>
    <form-type
      :contact="contact"
      :has-counter="false"
      :is-simplified="true"
      :parts="parts"
      :post-uri="postUri"
    />
  </div>
</template>

<script>
  import FormType from '@triotech/vue-core/src/components/FormType';

  export default {
    name: 'RGPDFormComponent',
    components: {
      FormType,
    },
    data() {
      return {
        postUri: 'public/contact/rgpd',
        text: {
          title: 'pages.rgpd.form_title',
          intro: 'pages.rgpd.form_intro',
        },
        parts: [{
          formType: 'input',
          name: 'name',
          type: 'text',
          placeholder: 'pages.rgpd.placeholder.name',
          required: true,
          isActive: true,
          label: 'pages.rgpd.label.name',
        }, {
          formType: 'input',
          name: 'email',
          type: 'email',
          placeholder: 'pages.rgpd.placeholder.email',
          required: true,
          isActive: true,
          label: 'pages.rgpd.label.email',
          pattern: /^.+@\S+\.\S+$/,
        }, {
          formType: 'select',
          name: 'demand_kind',
          placeholder: 'pages.rgpd.placeholder.demand_kind',
          label: 'pages.rgpd.label.demand_kind',
          multiple: false,
          options: [{
            value: 1,
            label: this.$t('pages.rgpd.options.unused_data'),
          }, {
            value: 2,
            label: this.$t('pages.rgpd.options.used_data'),
          }, {
            value: 3,
            label: this.$t('pages.rgpd.options.know_data'),
          }, {
            value: 4,
            label: this.$t('pages.rgpd.options.forget_right'),
          }],
          value: null,
        }],
        contact: {
          name: '',
          email: '',
          demand_kind: [],
        },
      };
    },
  };
</script>
