<template>
  <div class="animate-number-container">
    <div v-if="icon">
      <i
        class="ti ti-fw ti-lg ti-5x"
        :class="icon"
      ></i>
    </div>
    <div
      ref="dataNumber"
      class="number"
      :data-number="number"
    >
      {{ number }}
    </div>
    <p>
      <slot></slot>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'AnimateNumberComponent',
    props: {
      animateNumber: {
        type: Boolean,
        default: false,
      },
      finalNumber: {
        type: Number,
        default: 0,
      },
      icon: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        number: 0,
      };
    },
    watch: {
      animateNumber(newVal) {
        if (newVal) {
          let startTimestamp = null;
          const step = (currentTimestamp) => {
            if (!startTimestamp) {
              startTimestamp = currentTimestamp;
            }
            const progress = Math.min((currentTimestamp - startTimestamp) / 1500, 1);
            this.$refs.dataNumber.innerHTML = (Math.floor(progress * this.finalNumber)).toLocaleString('fr-FR');
            if (1 > progress) {
              window.requestAnimationFrame(step);
            }
          };
          window.requestAnimationFrame(step);
        }
      },
    },
  };
</script>
