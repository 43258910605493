<template>
  <div class="contact-form">
    <form-type
      :contact="contact"
      :has-counter="false"
      :has-intro="false"
      :is-simplified="true"
      :parts="parts"
      :post-uri="postUri"
      :text="text"
      @sent="onSuccess"
    />
  </div>
</template>

<script>
  import FormType from '@triotech/vue-core/src/components/FormType';

  export default {
    name: 'ContactFormComponent',
    components: {
      FormType,
    },
    data() {
      return {
        postUri: 'public/contact/',
        text: {
          title: 'pages.contact.form_title',
        },
        parts: [{
          formType: 'input',
          name: 'name',
          type: 'text',
          placeholder: 'pages.contact.placeholder.name',
          required: true,
          isActive: true,
          label: 'pages.contact.label.name',
        }, {
          formType: 'input',
          name: 'email',
          type: 'email',
          placeholder: 'pages.contact.placeholder.email',
          required: true,
          isActive: true,
          label: 'pages.contact.label.email',
        }, {
          formType: 'input',
          name: 'phone',
          type: 'text',
          placeholder: 'pages.contact.placeholder.phone',
          required: false,
          isActive: true,
          label: 'pages.contact.label.phone',
        }, {
          formType: 'textearea',
          name: 'message',
          placeholder: 'pages.contact.placeholder.message',
          required: true,
          isActive: true,
          label: 'pages.contact.label.message',
        }, {
          formType: 'input',
          name: 'used_data',
          type: 'checkbox',
          text: 'pages.contact.placeholder.rgpd',
          required: false,
          isActive: false,
          label: 'pages.contact.label.rgpd',
        }],
        contact: {
          name: '',
          email: '',
          phone: '',
          message: '',
          used_data: false,
        },
      };
    },
    methods: {
      onSuccess(contact) {
        this.$gtm.trackEvent({
          category: 'Contact',
          action: 'sent',
          value: contact.email,
        });

        this.$router.push({
          name: 'triotech.front.contact_success',
          params: { time: Date.now() },
          query: { source: 'contactAction' },
        });
      },
    },
  };
</script>
