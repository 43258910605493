<template>
  <div id="legal-notices">
    <section class="cover-image"></section>
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>
            {{ $t('pages.legal_notices.title') }}
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="$t('pages.legal_notices.general')"></div>
          <br />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="$t('pages.legal_notices.website')"></div>
          <br />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="$t('pages.legal_notices.host')"></div>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LegalNoticesPage',
  };
</script>

<style lang="scss" scoped>
  #legal-notices {
    .cover-image {
      background-image: url('/images/team-work.jpg');
    }
  }
</style>
