<template>
  <div id="app">
    <notifications
      :duration="5000"
      position="top left"
      :speed="500"
      :width="500"
    />
    <sticky-menu />
    <main>
      <router-view :key="$route.fullPath" />
    </main>
    <footer-menu />
  </div>
</template>

<script>
  import FooterMenu from './components/FooterMenu';
  import StickyMenu from './components/StickyMenu';

  export default {
    name: 'App',
    components: {
      FooterMenu,
      StickyMenu,
    },
  };
</script>

<style lang="scss">
@import 'src/scss/frontend';
</style>
