<template>
  <div :class="['container-fluid', 'py-md-3', blockClass]">
    <div class="container py-3 px-4 px-md-0 py-md-3 my-md-5">
      <div
        :class="[
          'row',
          'align-items-center',
          imageOnRight ? 'image-row' : 'image-row-reverse'
        ]"
      >
        <div class="col-sm-6">
          <responsive-picture-static
            css-class="card-img shadow"
            :extension="extension"
            :image-name="imageName"
            :path="path"
            :src="src"
          />
        </div>
        <div class="col-sm-6 text-center">
          <h2
            v-if="!onlyText"
            class="home-title p-0 mb-4"
          >
            {{ title }}
          </h2>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-html="text"></p>
          <link-icon
            v-if="link"
            :class="['btn text-light py-3 rounded-pill font-weight-bold mt-4 mb-lg-3', btnClass]"
            :link="link"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import LinkIcon from './LinkIcon';
  import ResponsivePictureStatic from './ResponsivePictureStatic';

  export default {
    components: {
      LinkIcon,
      ResponsivePictureStatic,
    },
    props: {
      blockClass: {
        type: String,
        default: '',
      },
      btnClass: {
        type: String,
        default: 'btn-orange',
      },
      extension: {
        type: String,
        default: '',
      },
      imageName: {
        type: String,
        default: '',
      },
      imageOnRight: {
        type: Boolean,
        default: false,
      },
      link: {
        type: [Object, Boolean],
        default() {
          return {};
        },
      },
      onlyText: {
        type: Boolean,
        default: false,
      },
      path: {
        type: String,
        default: '',
      },
      src: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },
  };
</script>
